import React, {PureComponent, ReactNode} from "react";
import Script from "next/script";

export class GoogleTagManager extends PureComponent {

    render(): ReactNode {

        let pixel = ''

        if (process.env.NEXT_PUBLIC_APP_ENV === "staging") {
            pixel =  `
                   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=dqQgFBGXjht5PcXd-dOFiQ&gtm_preview=env-297&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-MDLX4MW');
                    `
        }

        if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
            pixel =  `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=2j2FHEwwm-tSHwNyixxDAg&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-MDLX4MW');
                    `
        }

        return (
            <>
                <Script id={'google-tag-manager'} strategy={'afterInteractive'}>
                    {pixel}
                </Script>
            </>
        );
    }
}